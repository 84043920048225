import { Container, IDestroyOptions } from "pixi.js";
import { SlotSymbol } from "../symbols/SlotSymbol";
import { WinFrameAnimationFramesProvider } from "./resources/WinFrameAnimationFramesProvider";
import { WinFrameAnimation } from "./WinFrameAnimation";
import { AdvancedBloomFilter } from "pixi-filters";

export class WinLinesFactoryContainer extends Container {
    public constructor(
        private readonly resources: WinFrameAnimationFramesProvider,
    ) {
        super();
        this.filters = [new AdvancedBloomFilter({ threshold: 0.5, brightness: 1.2 })];
    }

    public createWinFrame(slotSymbol: SlotSymbol) {
        const winFrame = new WinFrameAnimation(this.resources.animationFrames);
        const localPosition = this.toLocal(slotSymbol.toGlobal({ x: 0, y: 0 }));
        localPosition.x -= (winFrame.width - slotSymbol.width) * 0.5;
        localPosition.y -= (winFrame.height - slotSymbol.height) * 0.5;
        winFrame.position = localPosition;
        winFrame.alpha = 0;
        this.addChild(winFrame);
        return winFrame;
    }

    public deleteWinFrame(winFrame: WinFrameAnimation) {
        this.removeChild(winFrame);
    }

    public destroy(options?: boolean | IDestroyOptions): void {
        super.destroy(options);
    }
}
