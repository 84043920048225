import { initializer, injectable } from "@launchtray/tsyringe-async";
import { ClientConfig } from "./ClientConfig";
import axios from "axios";
import { IClientConfigProvider } from "./IClientConfigProvider";
import { ClientConfigDto } from "./ClientConfigDto";

@injectable()
export class ClientConfigProvider implements IClientConfigProvider {
    private readonly configDir = "configs";
    private readonly configFile = "ClientConfig.json";

    private _clientConfig: ClientConfig;

    public constructor() { }

    get remoteURL(): string {
        return this._clientConfig.remoteURL;
    }

    get localURL(): string {
        return this._clientConfig.localURL;
    }

    get toRemote(): boolean {
        return this._clientConfig.toRemote;
    }

    get showFps(): boolean {
        return this._clientConfig.showFps;
    }

    set clientConfig(newValue: ClientConfig) {
        this._clientConfig = newValue;
    }

    @initializer()
    public async initialize(): Promise<void> {
        const filePath = `${this.configDir}/${this.configFile}`;
        const { data } = await axios.get<ClientConfigDto>(filePath);
        this.clientConfig = new ClientConfig(data);
    }
}
