import { Texture } from "pixi.js";
import { FreeSpinsSymbolAnimationResourcesPackage } from "./FreeSpinsSymbolAnimationResourcesPackage";

export class FreeSpinsSymbolFramesProvider extends FreeSpinsSymbolAnimationResourcesPackage {
    private instance: Array<Texture>;

    public get animationFrames(): Array<Texture> {
        if (!this.loaded) {
            throw new Error("You can not use frames provider before actual package is loaded.");
        }
        if (this.instance == null) {
            this.instance = [
                this.assets_freeSpinsSymbolAnimation_freeSpins_0_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_1_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_2_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_3_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_4_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_5_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_6_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_7_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_8_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_9_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_10_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_11_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_12_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_13_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_14_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_15_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_16_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_17_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_18_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_19_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_20_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_21_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_22_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_23_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_24_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_25_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_26_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_27_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_28_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_29_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_30_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_31_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_32_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_33_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_34_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_35_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_36_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_37_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_38_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_39_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_40_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_41_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_42_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_43_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_44_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_45_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_46_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_47_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_48_png,
                this.assets_freeSpinsSymbolAnimation_freeSpins_49_png
            ]
        }
        return this.instance;
    }
}