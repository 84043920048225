import { injectable } from "@launchtray/tsyringe-async";
import { INetworkClient } from "./INetworkClient";
import { HttpTransport } from "./HttpTransport";

@injectable()
export class NetworkClient implements INetworkClient {
    private readonly authTokenKey = 'jwt';

    private transport: HttpTransport;

    public get authToken() {
        const token = sessionStorage.getItem(this.authTokenKey);
        if (token === null) {
            this.authToken = '';
            return '';
        }

        return token;
    }

    public set authToken(token: string) {
        sessionStorage.setItem(this.authTokenKey, token);
    }

    public constructor(transport: HttpTransport) {
        this.transport = transport;
    }

    public async get<R, T = {}>(url: string, payload: T): Promise<R> {
        const response = await this.transport.get<R>(url, payload, this.authToken);
        return response;
    }

    public async post<R, T = {}>(url: string, payload: T): Promise<R> {
        const response = await this.transport.post<R>(url, payload, this.authToken);
        return response;
    }
}
