import { Container, IDestroyOptions, Sprite, Text } from "pixi.js";
import { UIResourcesPackage } from "../resources/UIResourcesPackage";
import { UITextStyle } from "../UITextStyle";
import { DefaultHoverHighlightButton } from "../../../../common/components/DefaultHoverHighlightButton";
import { DefaultHoverHighlightButtonFactory } from "../../../../../factories/DefaultHoverHighlightButtonFactory";
import { SlotViewModel } from "../../../models/SlotViewModel";
import { nameof } from "bindable-data";
import { CurrencyFormatUtils } from "../../../../common/utils/NumberFormatUtils";

export class BetConfig {
    public static readonly TOTAL_BETS = [0.2, 0.5, 0.8, 1, 1.5, 2, 2.5, 5, 7.5, 10];
    public static readonly MIN_BET_PER_LINE_VALUE = 1;
    public static readonly MAX_BET_PER_LINE_VALUE = 10;
}

export class BetField extends Container {
    private background: Sprite;
    private minusButton: DefaultHoverHighlightButton;
    private totalBetText: Text;
    private plusButton: DefaultHoverHighlightButton;
    private totalBetLabel: Text;
    private minusButtonHandler: () => void;
    private plusButtonHandler: () => void;

    private _betPerLine: number;
    private _totalBet: number;

    public get betPerLine() {
        return this._betPerLine;
    }

    public set betPerLine(value: number) {
        this._betPerLine = value;
        this.totalBetText.text = value;

        this.totalBet = value;
    }

    public get totalBet() {
        return this._totalBet;
    }

    private set totalBet(value: number) {
        this._totalBet = BetConfig.TOTAL_BETS[value - 1];
        this.totalBetText.text = CurrencyFormatUtils.getFormatedCurrency(this._totalBet);
    }

    public constructor(
        private readonly resources: UIResourcesPackage,
        private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        private readonly slotViewModel: SlotViewModel,
        initialTotalBet: number
    ) {
        super();
        this._totalBet = initialTotalBet;
        this._betPerLine = BetConfig.TOTAL_BETS.indexOf(initialTotalBet) + 1;
        this.minusButtonHandler = this.onMinusButtonClick.bind(this);
        this.plusButtonHandler = this.onPlusButtonClick.bind(this);
        this.slotViewModel.propertyChanged.add(this.onSlotViewModelUpdated, this);
        this.eventMode = slotViewModel.disabledSlotElements.value.includes('betFieldUi') || slotViewModel.spinAnimationInProgress.value ? 'none' : 'auto';
        this.build();
        this.layout();
    }

    private build() {
        this.background = new Sprite(this.resources.assets_ui_container_png);

        this.totalBetLabel = new Text("TOTAL BET", new UITextStyle(38));
        this.addChild(this.totalBetLabel);

        const minusButtonSprite = new Sprite(this.resources.assets_ui_bet_field_minus_button_png);
        this.minusButton = this.defaultHoverHighlightButtonFactory.createButton(minusButtonSprite);
        this.minusButton.onPress.connect(this.minusButtonHandler);
        this.background.addChild(this.minusButton.view);

        this.totalBetText = new Text(CurrencyFormatUtils.getFormatedCurrency(this._totalBet), new UITextStyle(38));
        this.totalBetText.anchor.set(0.5);
        this.background.addChild(this.totalBetText);

        const plusButtonSprite = new Sprite(this.resources.assets_ui_bet_field_plus_button_png);
        this.plusButton = this.defaultHoverHighlightButtonFactory.createButton(plusButtonSprite);
        this.plusButton.onPress.connect(this.plusButtonHandler);
        this.background.addChild(this.plusButton.view);
        this.addChild(this.background);
    }

    private layout() {
        this.totalBetLabel.position.set((this.background.width - this.totalBetLabel.width) * 0.5, 0);
        this.background.position.set(0, this.totalBetLabel.height - 10);
        this.minusButton.view.position.set(12, (this.background.height - this.minusButton.view.height) * 0.5);
        this.totalBetText.position.set(this.background.width * 0.5, this.background.height * 0.5);
        this.plusButton.view.position.set(this.background.width - this.plusButton.view.width - 12, (this.background.height - this.minusButton.view.height) * 0.5);
    }

    private onMinusButtonClick() {
        const newBetPerLineValue = this._betPerLine - 1;
        if (newBetPerLineValue >= BetConfig.MIN_BET_PER_LINE_VALUE) {
            this.betPerLine = newBetPerLineValue;
        }
    }

    private onPlusButtonClick() {
        const newBetPerLineValue = this._betPerLine + 1;
        if (newBetPerLineValue <= BetConfig.MAX_BET_PER_LINE_VALUE) {
            this.betPerLine = newBetPerLineValue;
        }
    }

    private onSlotViewModelUpdated(slotViewModel: SlotViewModel, propertyName: string) {
        if (propertyName === nameof(slotViewModel, "disabledSlotElements")) {
            this.eventMode = slotViewModel.disabledSlotElements.value.includes('betFieldUi') ? 'none' : 'auto';
        }
        if (propertyName === nameof(slotViewModel, "spinAnimationInProgress")) {
            this.eventMode = !slotViewModel.spinAnimationInProgress.value ? 'auto' : 'none';
        }
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        this.minusButton.onPress.disconnect(this.minusButtonHandler);
        this.minusButton.destroy();
        this.plusButton.onPress.disconnect(this.plusButtonHandler);
        this.plusButton.destroy();
        this.slotViewModel.propertyChanged.remove(this.onSlotViewModelUpdated, this);
        super.destroy(options);
    }
}
