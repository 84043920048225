import { Container, Graphics, IDestroyOptions, Sprite, Text } from "pixi.js";
import { IAudioManager } from "../../../../audio/IAudioManager";
import { DefaultHoverHighlightButtonFactory } from "../../../../factories/DefaultHoverHighlightButtonFactory";
import { ToggleButtonFactory } from "../../../../factories/ToggleButtonFactory";
import { IPlayerModel } from "../../../../player/IPlayerModel";
import { SlotController } from "../../controllers/SlotController";
import { SlotViewModel } from "../../models/SlotViewModel";
import { InfoContainer } from "./components/InfoContainer";
import { SoundContainer } from "./components/SoundContainer";
import { UIResourcesPackage } from "./resources/UIResourcesPackage";
import { Event } from "event-system";
import { DefaultHoverHighlightButton } from "../../../common/components/DefaultHoverHighlightButton";
import { nameof } from "bindable-data";
import { CurrencyFormatUtils } from "../../../common/utils/NumberFormatUtils";
import { UITextStyle } from "./UITextStyle";
import { ISlotControlPanel } from "./ISlotControlPanel";

export class MobileSlotControlPanel extends Container implements ISlotControlPanel {
    private soundContainer: SoundContainer;
    private autoButton: DefaultHoverHighlightButton;
    private spinButton: DefaultHoverHighlightButton;
    private isStopAction: boolean = false;
    private spinButtonHandler: () => void;
    private autoButtonHandler: () => void;
    public onSpin: Event<void>;
    private infoContainer: InfoContainer;
    private betPopupButton: DefaultHoverHighlightButton;
    private ribbon: Graphics;
    private balanceText: Text;
    private balanceLabelText: Text;
    private winPrizeText: Text;
    private winLabelText: Text;
    private _totalBet: number;
    private totalBetText: Text;
    private totalBetLabel: Text;

    private set totalBet(value: number) {
        this._totalBet = value;
        this.totalBetText.text = CurrencyFormatUtils.getFormatedCurrency(value);
    }

    public constructor(
        private readonly resources: UIResourcesPackage,
        private readonly playerModel: IPlayerModel,
        private readonly slotController: SlotController,
        private readonly slotViewModel: SlotViewModel,
        private readonly defaultHoverHighlightButtonFactory: DefaultHoverHighlightButtonFactory,
        private readonly toggleButtonFactory: ToggleButtonFactory,
        private readonly audioManager: IAudioManager,
        initialTotalBet: number = 0.2,
        private readonly initialWinPrize: number = 0
    ) {
        super();
        this._totalBet = initialTotalBet;
        this.isStopAction = this.slotViewModel.spinAnimationInProgress.value;
        this.onSpin = new Event();
        this.spinButtonHandler = this.onSpinButtonClick.bind(this);
        this.autoButtonHandler = this.onAutoButtonClick.bind(this);
        this.onBetPopupButtonClick = this.onBetPopupButtonClick.bind(this);
        this.slotViewModel.propertyChanged.add(this.onSlotViewModelPropertyChanged, this);
        this.slotViewModel.onUpdateWinPrize.add(this.onUpdateWinPrize, this);
        this.playerModel.propertyChanged.add(this.updateBalanceText, this);
        this.build();
        this.layout();
    }

    private build() {
        this.soundContainer = new SoundContainer(this.resources, this.slotController, this.toggleButtonFactory, this.audioManager.playAllSounds);
        this.addChild(this.soundContainer);

        const autoButtonTexture = this.slotViewModel.autoSpinEnabled.value ? this.resources.assets_ui_spin_container_auto_stop_button_png : this.resources.assets_ui_spin_container_auto_button_png;
        const autoButtonSprite = new Sprite(autoButtonTexture);
        this.autoButton = this.defaultHoverHighlightButtonFactory.createButton(autoButtonSprite);
        this.autoButton.onPress.connect(this.autoButtonHandler);
        this.addChild(this.autoButton.view);

        const spinButtonTexture = this.slotViewModel.spinAnimationInProgress.value ? this.resources.assets_ui_spin_container_spin_stop_button_png : this.resources.assets_ui_spin_container_spin_button_png;
        const spinButtonSprite = new Sprite(spinButtonTexture);
        this.spinButton = this.defaultHoverHighlightButtonFactory.createButton(spinButtonSprite);
        this.spinButton.onPress.connect(this.spinButtonHandler);
        this.addChild(this.spinButton.view);

        this.infoContainer = new InfoContainer(this.resources, this.slotController, this.defaultHoverHighlightButtonFactory, this.slotViewModel);
        this.addChild(this.infoContainer);

        const betPopupSprite = new Sprite(this.resources.assets_ui_bet_field_bet_button_png);
        this.betPopupButton = this.defaultHoverHighlightButtonFactory.createButton(betPopupSprite);
        this.betPopupButton.onPress.connect(this.onBetPopupButtonClick);
        this.addChild(this.betPopupButton.view);

        this.ribbon = new Graphics().beginFill(0x01040d).drawRect(0, 0, 2048, 250);
        this.addChild(this.ribbon);
        this.balanceText = new Text(CurrencyFormatUtils.getFormatedCurrency(this.playerModel.balance.value), new UITextStyle(72));
        this.ribbon.addChild(this.balanceText);
        this.balanceLabelText = new Text("BALANCE", new UITextStyle(84));
        this.ribbon.addChild(this.balanceLabelText);
        this.winPrizeText = new Text(this.formatWinPrizeToString(this.initialWinPrize), new UITextStyle(72));
        this.ribbon.addChild(this.winPrizeText);
        this.winLabelText = new Text("WIN", new UITextStyle(84));
        this.ribbon.addChild(this.winLabelText);
        this.totalBetText = new Text(CurrencyFormatUtils.getFormatedCurrency(this._totalBet), new UITextStyle(72));
        this.ribbon.addChild(this.totalBetText);
        this.totalBetLabel = new Text("BET", new UITextStyle(84));
        this.ribbon.addChild(this.totalBetLabel);

        this.spinButton.enabled = !this.slotViewModel.disabledSlotElements.value.includes('spinButtonUi');
        this.autoButton.enabled = !this.slotViewModel.disabledSlotElements.value.includes('autoSpinButtonUi');
        this.betPopupButton.enabled = !this.slotViewModel.disabledSlotElements.value.includes('betFieldUi') && !this.slotViewModel.spinAnimationInProgress.value;
    }

    private layout() {
        this.soundContainer.width = 300;
        this.soundContainer.height = 300;
        this.soundContainer.position.set(170, 250);

        this.autoButton.view.width = 300;
        this.autoButton.view.height = 300;
        this.autoButton.view.position.set(1490, 250);

        this.spinButton.view.width = 750;
        this.spinButton.view.height = 750;
        this.spinButton.view.position.set((this.width - this.spinButton.view.width * 1.125) * 0.5, 260);

        this.infoContainer.width = 300;
        this.infoContainer.height = 300;
        this.infoContainer.position.set(170, 750);

        this.betPopupButton.view.width = 300;
        this.betPopupButton.view.height = 300;
        this.betPopupButton.view.position.set(1490, 750);

        this.ribbon.position.set(0, 1250);
        this.balanceText.anchor.set(0.5);
        this.balanceText.position.set(370, 125);
        this.balanceLabelText.position.set(210, 250);
        this.winPrizeText.anchor.set(0.5);
        this.winPrizeText.position.set(1010, 125);
        this.winLabelText.position.set(945, 250);
        this.totalBetText.anchor.set(0.5);
        this.totalBetText.position.set(1600, 125);
        this.totalBetLabel.position.set(1535, 250);
    }

    private async onSpinButtonClick() {
        if (!this.isStopAction) {
            this.onSpin.emit();
        } else {
            this.slotController.stopReelsFast();
        }
    }

    private onAutoButtonClick() {
        this.slotController.toggleAutoSpin();
    }

    private onSlotViewModelPropertyChanged(model: SlotViewModel, key: string) {
        switch (key) {
            case nameof(model, "autoSpinEnabled"): {
                const texture = model.autoSpinEnabled.value ? this.resources.assets_ui_spin_container_auto_stop_button_png : this.resources.assets_ui_spin_container_auto_button_png;
                const viewRef: Sprite = this.autoButton.view as Sprite;
                viewRef.texture = texture;
                break;
            }
            case nameof(model, "spinAnimationInProgress"): {
                this.isStopAction = model.spinAnimationInProgress.value;
                const texture = model.spinAnimationInProgress.value ? this.resources.assets_ui_spin_container_spin_stop_button_png : this.resources.assets_ui_spin_container_spin_button_png;
                const viewRef: Sprite = this.spinButton.view as Sprite;
                viewRef.texture = texture;
                this.betPopupButton.enabled = !model.spinAnimationInProgress.value;
                break;
            }
            case nameof(model, "disabledSlotElements"): {
                this.spinButton.enabled = !model.disabledSlotElements.value.includes('spinButtonUi');
                this.autoButton.enabled = !model.disabledSlotElements.value.includes('autoSpinButtonUi');
                this.betPopupButton.enabled = !model.disabledSlotElements.value.includes('betFieldUi');
                break;
            }
            default: break;
        }
    }

    private updateBalanceText(playerModel: IPlayerModel, key: string) {
        if (key == nameof(playerModel, "balance")) {
            this.balanceText.text = CurrencyFormatUtils.getFormatedCurrency(playerModel.balance.value);
        }
    }

    private formatWinPrizeToString(value: number): string {
        return value === 0 ? '' : CurrencyFormatUtils.getFormatedCurrency(value);
    }

    private onUpdateWinPrize(prize: number): void {
        this.winPrizeText.text = this.formatWinPrizeToString(prize);
    }

    private async onBetPopupButtonClick() {
        const resultBet = await this.slotController.showMobileBetPopup(this._totalBet);
        this.totalBet = resultBet;
    }

    public getTotalBet(): number {
        return this._totalBet;
    }

    public override destroy(options?: boolean | IDestroyOptions): void {
        this.autoButton.onPress.disconnect(this.autoButtonHandler);
        this.autoButton.destroy();
        this.spinButton.onPress.disconnect(this.spinButtonHandler);
        this.spinButton.destroy();
        this.betPopupButton.onPress.disconnect(this.onBetPopupButtonClick);
        this.betPopupButton.destroy();
        this.slotViewModel.propertyChanged.remove(this.onSlotViewModelPropertyChanged, this);
        this.playerModel.propertyChanged.remove(this.updateBalanceText, this);
        this.slotViewModel.onUpdateWinPrize.remove(this.onUpdateWinPrize, this);
        super.destroy(options);
    }

}