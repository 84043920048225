import { Texture } from "pixi.js";
import { BonusSymbolAnimationResourcesPackage } from "./BonusSymbolAnimationResourcesPackage";

export class BonusSymbolFramesProvider extends BonusSymbolAnimationResourcesPackage {
    private instance: Array<Texture>;

    public get animationFrames(): Array<Texture> {
        if (!this.loaded) {
            throw new Error("You can not use frames provider before actual package is loaded.");
        }
        if (this.instance == null) {
            this.instance = [
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_0_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_1_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_2_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_3_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_4_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_5_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_6_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_7_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_8_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_9_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_10_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_11_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_12_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_13_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_14_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_15_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_16_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_17_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_18_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_19_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_20_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_21_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_22_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_23_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_24_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_25_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_26_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_27_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_28_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_29_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_30_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_31_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_32_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_33_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_34_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_35_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_36_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_37_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_38_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_39_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_40_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_41_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_42_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_43_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_44_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_45_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_46_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_47_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_48_png,
                this.assets_bonusSymbolAnimation_bonusSymbolAnimation_49_png
            ]
        }
        return this.instance;
    }
}