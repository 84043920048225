import { BundleResourcesPackage } from "resource-system";
import { Spritesheet, Texture } from "pixi.js";
export class StickyWildAnimationResourcePackage extends BundleResourcesPackage {
    public constructor() {
        super("StickyWildAnimationResourcePackage");
    }
    public get assets_stickyWildAnimation_stickyWildAnimation_0_1x_atlas_json(): Spritesheet {
        return this.resource<Spritesheet>("assets/stickyWildAnimation/stickyWildAnimation-0_1x.atlas.json");
    }
    public get assets_stickyWildAnimation_stickyWild_0_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_0_1x_atlas_json.textures["stickyWild_0.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_10_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_0_1x_atlas_json.textures["stickyWild_10.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_11_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_0_1x_atlas_json.textures["stickyWild_11.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_12_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_0_1x_atlas_json.textures["stickyWild_12.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_13_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_0_1x_atlas_json.textures["stickyWild_13.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_14_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_0_1x_atlas_json.textures["stickyWild_14.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_15_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_0_1x_atlas_json.textures["stickyWild_15.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_16_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_0_1x_atlas_json.textures["stickyWild_16.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_17_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_0_1x_atlas_json.textures["stickyWild_17.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_18_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_0_1x_atlas_json.textures["stickyWild_18.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_19_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_0_1x_atlas_json.textures["stickyWild_19.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_2_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_0_1x_atlas_json.textures["stickyWild_2.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_20_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_0_1x_atlas_json.textures["stickyWild_20.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_21_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_0_1x_atlas_json.textures["stickyWild_21.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_22_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_0_1x_atlas_json.textures["stickyWild_22.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_23_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_0_1x_atlas_json.textures["stickyWild_23.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_1_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_0_1x_atlas_json.textures["stickyWild_1.png"];
    }
    public get assets_stickyWildAnimation_stickyWildAnimation_0_1x_atlas_png(): Texture {
        return this.resource<Texture>("assets/stickyWildAnimation/stickyWildAnimation-0_1x.atlas.png");
    }
    public get assets_stickyWildAnimation_stickyWildAnimation_1_1x_atlas_json(): Spritesheet {
        return this.resource<Spritesheet>("assets/stickyWildAnimation/stickyWildAnimation-1_1x.atlas.json");
    }
    public get assets_stickyWildAnimation_stickyWild_24_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_1_1x_atlas_json.textures["stickyWild_24.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_25_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_1_1x_atlas_json.textures["stickyWild_25.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_26_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_1_1x_atlas_json.textures["stickyWild_26.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_27_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_1_1x_atlas_json.textures["stickyWild_27.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_28_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_1_1x_atlas_json.textures["stickyWild_28.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_29_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_1_1x_atlas_json.textures["stickyWild_29.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_3_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_1_1x_atlas_json.textures["stickyWild_3.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_30_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_1_1x_atlas_json.textures["stickyWild_30.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_31_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_1_1x_atlas_json.textures["stickyWild_31.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_32_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_1_1x_atlas_json.textures["stickyWild_32.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_33_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_1_1x_atlas_json.textures["stickyWild_33.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_34_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_1_1x_atlas_json.textures["stickyWild_34.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_35_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_1_1x_atlas_json.textures["stickyWild_35.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_36_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_1_1x_atlas_json.textures["stickyWild_36.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_37_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_1_1x_atlas_json.textures["stickyWild_37.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_38_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_1_1x_atlas_json.textures["stickyWild_38.png"];
    }
    public get assets_stickyWildAnimation_stickyWildAnimation_1_1x_atlas_png(): Texture {
        return this.resource<Texture>("assets/stickyWildAnimation/stickyWildAnimation-1_1x.atlas.png");
    }
    public get assets_stickyWildAnimation_stickyWildAnimation_2_1x_atlas_json(): Spritesheet {
        return this.resource<Spritesheet>("assets/stickyWildAnimation/stickyWildAnimation-2_1x.atlas.json");
    }
    public get assets_stickyWildAnimation_stickyWild_39_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_2_1x_atlas_json.textures["stickyWild_39.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_4_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_2_1x_atlas_json.textures["stickyWild_4.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_40_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_2_1x_atlas_json.textures["stickyWild_40.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_41_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_2_1x_atlas_json.textures["stickyWild_41.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_42_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_2_1x_atlas_json.textures["stickyWild_42.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_43_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_2_1x_atlas_json.textures["stickyWild_43.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_44_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_2_1x_atlas_json.textures["stickyWild_44.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_45_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_2_1x_atlas_json.textures["stickyWild_45.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_46_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_2_1x_atlas_json.textures["stickyWild_46.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_47_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_2_1x_atlas_json.textures["stickyWild_47.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_48_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_2_1x_atlas_json.textures["stickyWild_48.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_5_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_2_1x_atlas_json.textures["stickyWild_5.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_6_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_2_1x_atlas_json.textures["stickyWild_6.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_7_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_2_1x_atlas_json.textures["stickyWild_7.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_8_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_2_1x_atlas_json.textures["stickyWild_8.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_9_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_2_1x_atlas_json.textures["stickyWild_9.png"];
    }
    public get assets_stickyWildAnimation_stickyWild_49_png(): Texture {
        return this.assets_stickyWildAnimation_stickyWildAnimation_2_1x_atlas_json.textures["stickyWild_49.png"];
    }
    public get assets_stickyWildAnimation_stickyWildAnimation_2_1x_atlas_png(): Texture {
        return this.resource<Texture>("assets/stickyWildAnimation/stickyWildAnimation-2_1x.atlas.png");
    }
}

