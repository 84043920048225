import { BundleResourcesPackage } from "resource-system";
import { Spritesheet, Texture } from "pixi.js";
export class UIResourcesPackage extends BundleResourcesPackage {
    public constructor() {
        super("UIResourcesPackage");
    }
    public get assets_ui_ui_1x_atlas_json(): Spritesheet {
        return this.resource<Spritesheet>("assets/ui/ui_1x.atlas.json");
    }
    public get assets_ui_mobile_bet_field_substrate_png(): Texture {
        return this.assets_ui_ui_1x_atlas_json.textures["mobile_bet_field/substrate.png"];
    }
    public get assets_ui_container_png(): Texture {
        return this.assets_ui_ui_1x_atlas_json.textures["container.png"];
    }
    public get assets_ui_spin_container_spin_button_png(): Texture {
        return this.assets_ui_ui_1x_atlas_json.textures["spin_container/spin_button.png"];
    }
    public get assets_ui_spin_container_spin_stop_button_png(): Texture {
        return this.assets_ui_ui_1x_atlas_json.textures["spin_container/spin_stop_button.png"];
    }
    public get assets_ui_mobile_bet_field_cancel_button_png(): Texture {
        return this.assets_ui_ui_1x_atlas_json.textures["mobile_bet_field/cancel_button.png"];
    }
    public get assets_ui_mobile_bet_field_confirm_button_png(): Texture {
        return this.assets_ui_ui_1x_atlas_json.textures["mobile_bet_field/confirm_button.png"];
    }
    public get assets_ui_mobile_bet_field_totalBetLabel_png(): Texture {
        return this.assets_ui_ui_1x_atlas_json.textures["mobile_bet_field/totalBetLabel.png"];
    }
    public get assets_ui_mobile_bet_field_bet_container_png(): Texture {
        return this.assets_ui_ui_1x_atlas_json.textures["mobile_bet_field/bet_container.png"];
    }
    public get assets_ui_spin_container_auto_button_png(): Texture {
        return this.assets_ui_ui_1x_atlas_json.textures["spin_container/auto_button.png"];
    }
    public get assets_ui_spin_container_auto_stop_button_png(): Texture {
        return this.assets_ui_ui_1x_atlas_json.textures["spin_container/auto_stop_button.png"];
    }
    public get assets_ui_bet_field_bet_button_png(): Texture {
        return this.assets_ui_ui_1x_atlas_json.textures["bet_field/bet_button.png"];
    }
    public get assets_ui_info_container_info_button_png(): Texture {
        return this.assets_ui_ui_1x_atlas_json.textures["info_container/info_button.png"];
    }
    public get assets_ui_mobile_bet_field_perLineLabel_png(): Texture {
        return this.assets_ui_ui_1x_atlas_json.textures["mobile_bet_field/perLineLabel.png"];
    }
    public get assets_ui_sound_container_soundOff_png(): Texture {
        return this.assets_ui_ui_1x_atlas_json.textures["sound_container/soundOff.png"];
    }
    public get assets_ui_sound_container_soundOn_png(): Texture {
        return this.assets_ui_ui_1x_atlas_json.textures["sound_container/soundOn.png"];
    }
    public get assets_ui_mobile_bet_field_minus_button_png(): Texture {
        return this.assets_ui_ui_1x_atlas_json.textures["mobile_bet_field/minus_button.png"];
    }
    public get assets_ui_mobile_bet_field_plus_button_png(): Texture {
        return this.assets_ui_ui_1x_atlas_json.textures["mobile_bet_field/plus_button.png"];
    }
    public get assets_ui_bet_field_minus_button_png(): Texture {
        return this.assets_ui_ui_1x_atlas_json.textures["bet_field/minus_button.png"];
    }
    public get assets_ui_bet_field_plus_button_png(): Texture {
        return this.assets_ui_ui_1x_atlas_json.textures["bet_field/plus_button.png"];
    }
    public get assets_ui_ui_1x_atlas_png(): Texture {
        return this.resource<Texture>("assets/ui/ui_1x.atlas.png");
    }
}

