import { AnimatedSprite, Texture } from "pixi.js";
import { PromiseCompletionSource } from "scene-manager";

export class AsyncAnimatedSprite extends AnimatedSprite {

    public constructor(textures: Array<Texture>, animationSpeed: number = 0.5, loop: boolean = false) {
        super(textures);
        this.animationSpeed = animationSpeed;
        this.loop = loop;
    }

    public async playAsync(): Promise<void> {
        const animationComplete = new PromiseCompletionSource<void>();
        this.onComplete = () => animationComplete.complete();
        this.play();
        await animationComplete.result;
    }
}