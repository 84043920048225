import { IUserModel } from "./IUserModel";
import { singleton } from "@launchtray/tsyringe-async";
import { UserAddress } from "./UserAddress";

@singleton()
export class UserModelStub implements IUserModel {
    private readonly loginKey = 'loginKey';

    public readonly login: string = 'login';
    public readonly gender: string = 'male';
    public readonly firstName: string = 'John';
    public readonly lastName: string = 'Doe';
    public readonly personId: string = 'AB000001';
    public readonly nationality: string = 'XY';
    public readonly birthDate: Date = new Date('2000-01-02');
    public readonly birthPlace: string = 'XY';
    public readonly address: UserAddress = { country: 'England', city: 'London', street: 'Baker', houseNumber: '221B', box: '4', postalCode: '000000' };

    public constructor() {
        const loginFromStorage = sessionStorage.getItem(this.loginKey);
        if (loginFromStorage === null) { }
    }

    public updateUser(_value: any): void {
    }
}
